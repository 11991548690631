<template>
  <vx-card v-show="!loading">
    <vs-row>
      <h4 class="mb-5">Edit Product</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Product Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <!-- <vs-col vs-w="12" class="mb-3">
            <vs-input
              type="number"
              class="w-full"
              label="Price (*)"
              v-model="payload.price"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("price")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Brand (*)</span>
            <v-select
              label="name"
              :options="brandOptions"
              :reduce="brand => brand.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.brand_id"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("brand_id")
            }}</span>
          </vs-col> -->

          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Category Product</span>
            <v-select
              @input="payload.sub_category_product_id = ''"
              :options="serviceOptions"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="service"
            />
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Sub Category Product (*)</span>
            <v-select
              label="name"
              :options="subCategoryProductOptions"
              :reduce="(category) => category.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.sub_category_product_id"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("sub_category_product_id")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

// Store Module
import moduleProductManagement from "@/store/product-management/moduleProductManagement.js";

export default {
  name: "ProductEdit",

  metaInfo: {
    title: "Edit Product",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    "v-select": vSelect,
  },

  data: () => ({
    payload: {
      brand_id: "",
      sub_category_product_id: "",
      name: "",
      price: "",
    },

    service: "",
    loading: true,

    // Vselect options
    brandOptions: [],
    subCategoryProductOptions: [],
    serviceOptions: [],
  }),

  watch: {
    service(service) {
      this.getSubCategoryProducts(service);
    },
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.slug);
    },
  },

  methods: {
    getData() {
      this.$vs.loading({
        type: "sound",
      });

      this.$store
        .dispatch("productManagement/fetchProduct", this.decryptedID)
        .then(({ data }) => {
          console.log(data);
          // this.payload.brand_id = data.data.brand.id;
          this.payload.sub_category_product_id =
            data.data.sub_category_product.id;
          this.service = data.data.sub_category_product.category_product.name;
          this.payload.name = data.data.name;
          this.payload.price = data.data.price;
        })
        .catch((err) => {
          console.log(err);
          this.$catchErrorResponse(err);
        })
        .finally((res) => {
          this.loading = false;
          this.$vs.loading.close();
        });
    },

    getBrands() {
      axios
        .get("/brand")
        .then(({ data: res }) => {
          this.brandOptions = res.data.map((brand) => {
            return {
              id: brand.id,
              name: brand.name,
            };
          });
        })
        .catch((err) => this.$catchErrorResponse(err));
    },

    getCategoryProducts() {
      axios
        .get("/category-product")
        .then(({ data: res }) => {
          this.serviceOptions = res.data.map((service) => service.name);
        })
        .catch((err) => this.$catchErrorResponse(err));
    },

    getSubCategoryProducts(service) {
      axios
        .get(`/sub-category-product?service=${service}`)
        .then(({ data: res }) => {
          this.subCategoryProductOptions = res.data.map(
            (subCategoryProduct) => {
              return {
                id: subCategoryProduct.id,
                name: subCategoryProduct.name,
              };
            }
          );
        })
        .catch((err) => this.$catchErrorResponse(err));
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set(
        "sub_category_product_id",
        this.payload.sub_category_product_id
      );
      formData.set("brand_id", this.payload.brand_id);
      formData.set("name", this.payload.name);
      formData.set("price", this.payload.price);
      formData.append("_method", "PUT");

      this.$store
        .dispatch("productManagement/updateProduct", {
          id: this.decryptedID,
          body: formData,
        })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });

          this.$router.push("/products");
        })
        .catch((err) => {
          this.$catchErrorResponse(err);
        });
    },
  },

  created() {
    if (!moduleProductManagement.isRegistered) {
      this.$store.registerModule("productManagement", moduleProductManagement);
      moduleProductManagement.isRegistered = true;
    }

    this.getBrands();
    this.getCategoryProducts();
    this.getSubCategoryProducts(this.service);

    this.getData();
  },
};
</script>

<style></style>
