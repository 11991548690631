import axios from '@/axios.js'

export default {
  fetchProducts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/product')
        .then(({data: response}) => {
          commit('SET_PRODUCTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchProduct (context, productId) {
    return new Promise((resolve, reject) => {
      axios.get(`/product/${productId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeProduct (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/product', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateProduct (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/product/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyProduct ({ commit }, productId) {
    return new Promise((resolve, reject) => {
      axios.delete(`product/${productId}`)
        .then((response) => {
          commit('REMOVE_RECORD', productId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
